<template>
  <div class="online_open_account_box">
    <div class="online_open_account_box1">
      <div class="online_open_account_title">
        <span class="online_open_account_title_title">开户前需要准备：</span>
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd1.png" /> <span>电脑</span></span
        >
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd5.png" /> <span>摄像头</span></span
        >
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd2.png" /> <span>扬声器</span></span
        >
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd3.png" /> <span>麦克风</span></span
        >
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd7.png" /> <span>手机</span></span
        >
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd6.png" /> <span>身份证</span></span
        >
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd8.png" /> <span>银行卡</span></span
        >
        <span class="online_open_account_title_content">
          <img src="@/assets/img/yd4.png" /> <span>手写签名</span></span
        >
      </div>
      <div class="online_open_account_bottom">
        <div class="futures_account">
          <div class="futures_account_title">期货开户</div>
          <div class="futures_account_tab">
            <div
              :class="idx == 1 ? 'highlight' : 'futures_account_tab_time'"
              @mouseover="handle_futures_account_tab(1)"
            >
              手机开户
            </div>
            <div
              :class="idx == 2 ? 'highlight' : 'futures_account_tab_time'"
              @mouseover="handle_futures_account_tab(2)"
            >
              PC端开户
            </div>
          </div>
          <div v-show="idx === 1">
            <div class="futures_account_app">
              <div class="futures_account_app_code">
                <!-- <img src="@/assets/img/yd222.png" /> -->
                <vue-qr
                  :size="210"
                  :text="
                    'http://www.hyfutures.com/#/app_download_tip?android=' +
                    android +
                    '&ios=' +
                    iphone
                  "
                ></vue-qr>
              </div>
            </div>
            <p class="futures_account_app_bottom">
              扫描二维码，马上手机开户 开户时间 9:00-17:00(交易日)
            </p>
          </div>
          <div v-show="idx === 2">
            <div class="futures_account_pc">
              <div class="futures_account_pc_img">
                <img
                  src="@/assets/img/yd17.png"
                  @click="handle_futures_account_pc"
                />
              </div>
            </div>
            <p
              style="
                font-size: 24px;
                color: #4c2e03;
                text-align: center;
                margin-bottom: 15px;
              "
            >
              PC端开户
            </p>
            <p
              style="
                font-size: 18px;
                color: #4c2e03;
                text-align: center;
                margin-bottom: 15px;
              "
            >
              开户时间 9:00-17:00(交易日)
            </p>
            <p style="font-size: 13px; color: #4c2e03; text-align: center">
              点击图标将跳转至中国期货市场监控中心开户页面
            </p>
          </div>
        </div>
        <div class="online_open_account_box_right">
          <div class="opening_account_steps">
            <div class="opening_account_steps_title">
              <span class="opening_account_steps_title_title"
                >PC端开户步骤</span
              >
              <span
                class="opening_account_step_titles_details"
                @click="handle_column('开户文章')"
                >详细 ></span
              >
            </div>
            <div class="opening_account_steps_bottom">
              <div class="opening_account_steps_bottom_item_1">
                <img src="@/assets/img/yd9.png" />
                <span> 填写资料 </span>
              </div>
              <img
                src="@/assets/img/yd12.png"
                class="opening_account_steps_bottom_item_2"
              />
              <div class="opening_account_steps_bottom_item_1">
                <img src="@/assets/img/yd10.png" />
                <span> 视频认证 </span>
              </div>
              <img
                src="@/assets/img/yd12.png"
                class="opening_account_steps_bottom_item_2"
              />
              <div class="opening_account_steps_bottom_item_1">
                <img src="@/assets/img/yd11.png" />
                <span> 签署协议 </span>
              </div>
            </div>
          </div>
          <div class="more_page">
            <div class="more_page_item" @click="handle_column('营业网点')">
              <img src="@/assets/img/yd13.png" />
              <span class="more_page_text">营业网点</span>
            </div>
            <div class="more_page_item" @click="handle_column('手机开户步骤')">
              <img src="@/assets/img/yd14.png" />
              <span class="more_page_text">手机开户步骤</span>
            </div>
            <div class="more_page_item" @click="handle_column('常见问题')">
              <img src="@/assets/img/yd15.png" />
              <span class="more_page_text">常见问题</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  data() {
    return {
      idx: 1,
      android: '',
      iphone: ''
    }
  },
  components: {
    vueQr
  },
  created() {
    // 获取开户链接设置
    this.getSetUp()
  },
  mounted() {
    this.handle_scrollTop();
  },
  methods: {
    //获取开户设置
    getSetUp() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/first_app/get`
      }).then(res => {
        console.log(res)
        this.android = res.data.data.android
        this.iphone = res.data.data.iphone
      })
    },
    handle_scrollTop() {
      this.$("html,body").animate({ scrollTop: 0 }, 500); //回到顶端
    },
    handle_futures_account_pc() {
      window.open('https://hyqh.cfmmc.com')
    },
    handle_futures_account_tab(data) {
      this.idx = data
    },
    handle_column(data) {
      if (data === '营业网点') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1460440529895456',
            id: '1458834147115040'
          },
        });
      } else if (data === '常见问题') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1469893912297506',
            id: '1469895292223521'
          },
        });
      } else if (data === '手机开户步骤') {
        this.$router.push({
          path: "/details",
          query: {
            id: '1469167423193121',
            my_id: '1557962248159266',
          },
        });
      } else if (data === '开户文章') {
        this.$router.push({
          path: "/details",
          query: {
            id: '1469167423193121',
            my_id: '1469370750468130',
          },
        });
      }
    }
  }
}
</script>
<style scoped>
.online_open_account_box_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.more_page_item:hover,
.opening_account_steps:hover,
.futures_account:hover,
.online_open_account_title:hover {
  box-shadow: 0px 0px 10px #999;
}
.opening_account_step_titles_details:hover {
  color: #c21d14;
}
.more_page_item {
  padding: 50px 0 34px 0;
  width: 202px;
  height: 246px;
  background: #ffffff;
  opacity: 0.8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.more_page_text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5e0e0e;
}
.more_page {
  width: 657px;
  height: 246px;
  display: flex;
  justify-content: space-between;
}
.opening_account_steps_bottom_item_1 {
  display: flex;
  flex-direction: column;
}
.opening_account_steps_bottom_item_1 > img {
  margin-bottom: 15px;
}
.opening_account_steps_bottom > img {
  width: 128px;
  height: 86px;
}
.opening_account_steps_bottom {
  display: flex;
  justify-content: space-between;
}
.opening_account_steps_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.opening_account_steps_title_title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5e0e0e;
}
.opening_account_step_titles_details {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8c3d09;
  cursor: pointer;
}
.opening_account_steps {
  width: 657px;
  height: 230px;
  background: #ffffff;
  opacity: 0.9;
  border-radius: 10px;
  padding: 25px 34px 34px 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.online_open_account_bottom {
  display: flex;
  justify-content: space-between;
}
.futures_account_pc {
  margin-bottom: 35px;
  margin-top: 55px;
}
.futures_account_app_bottom {
  width: 238px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4c2e03;
  line-height: 33px;
  margin: 25px auto 0 auto;
  text-align: center;
}
.futures_account_app_code {
  width: 230px;
  height: 230px;
  margin: 34px auto 0 auto;
}
.futures_account_pc_img {
  width: 145px;
  height: 154px;
  margin: 0 auto;
  cursor: pointer;
}
.highlight {
  width: 124px;
  height: 40px;
  background: #c21d14;
  opacity: 0.8;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.futures_account_tab_time {
  width: 124px;
  height: 40px;
  background: #f4e3cd;
  border: 1px solid #cc4439;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cc4438;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.futures_account_tab {
  display: flex;
  margin: 34px 34px 0 34px;
}
.futures_account_title {
  width: 320px;
  height: 50px;
  background: #c21d14;
  opacity: 0.8;
  border-radius: 10px 10px 0px 0px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
}
.futures_account {
  width: 320px;
  height: 499px;
  background: linear-gradient(0deg, #f6e2c7, #fcf2e5);
  opacity: 0.8;
  border-radius: 10px;
}
.online_open_account_title_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.online_open_account_title_content img {
  margin-right: 5px;
}
.online_open_account_box {
  background: url("../../assets/img/ydbj.png");
  background-size: 100% 100%;
  padding-bottom: 120px;
  margin-bottom: -80px;
  background-repeat: no-repeat;
}
.online_open_account_box1 {
  margin: 0 auto;
  padding-top: 30px;
  width: 1000px;
}
.online_open_account_title {
  width: 1000px;
  height: 81px;
  background: #fffdfa;
  opacity: 0.6;
  border-radius: 10px;
  margin-bottom: 25px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
.online_open_account_title_title {
  height: 81px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8c3d09;
  line-height: 81px;
}
* {
  box-sizing: border-box;
}
</style>
